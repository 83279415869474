import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    headerImage,
    description,
    title,
    heading1,
    heading1Description,
    col1Img,
    col1Heading,
    col1Desc,
    col2Img,
    col2Heading,
    col2Desc,
    col3Img,
    col3Heading,
    col3Desc,
    bigSectionImg,
    bigSectionHeading,
    bigSectionDesc,
    bigSectionButtonText,
    bigSectionButtonLink,
    testimonialImg,
    testimonialVideoLink,
    testimonial,
    testimonialTranslation,
    testimonialPerson,
    testimonialPersonDesc,
    sponsorSectionHeading,
    sponsorSectionDesc,
    sponsorSectionBtnText,
    sponsorSectionBtnLink,
    mainSectionButtonText,
    mainSectionButtonLink,
    mainSectionButtonText2,
    mainSectionButtonLink2
  } = data.allContentfulPageHome.edges[0].node;

  const {
    bannerEmbeddedUrl,
  } = data.allContentfulGivingTuesday2020.edges[0].node;

  const {
    heading,
    description: statisticsDesc
  } = data.allContentfulSectionStatistics.edges[0].node;

  const {
    heading: sponsorshipHeading,
    description: sponsorshipDesc,
    sponsorship1Img,
    sponsorship1Heading,
    sponsorship1Desc,
    sponsorship1Link,
    sponsorship1LinkText,
    sponsorship2Img,
    sponsorship2Heading,
    sponsorship2Desc,
    sponsorship2Link,
    sponsorship2LinkText,
    sponsorship3Img,
    sponsorship3Heading,
    sponsorship3Desc,
    sponsorship3Link,
    sponsorship3LinkText
  } = data.allContentfulSectionOtherSponsorships.edges[0].node;
  return (
    <Layout>
      <HelmetComponent description={description} image={headerImage.fluid.src} title={title} />
      <section className="page-sub-header hero" id="about-hero">
        <div className="w-100">
          <Img className="w-100 bg-img-overlay position-absolute" fluid={headerImage.fluid} />
          <Img className="w-100 bg-img position-absolute" fluid={headerImage.fluid} />
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-lg-7 col-md-9 col-xs-12">
                  <h1>{title}</h1>
                  <p>{description.description}</p>
                  <div className="row mt-3">
                    <div className="col-md-6 mt-3">
                      <a className="btn-reg btn-white" href={mainSectionButtonLink}>
                        {mainSectionButtonText}
                      </a>
                    </div>
                    <div className="col-md-6 mt-3">
                      <a className="btn-trans" data-toggle="modal" href="#mainVidModal">
                        <i className="material-icons">play_circle_outline</i>{' '}
                        {mainSectionButtonText2}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="mainVidModal">
            <div className="modal-dialog">
              <div className="modal-content transparent-modal">
                <div className="modal-header">
                  <button aria-hidden="true" className="close" data-dismiss="modal" type="button">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="embed-container">
                    <iframe
                      allowFullScreen
                      data-src={mainSectionButtonLink2}
                      frameBorder="0"
                      title="testimonial"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-area" id="primary">
        <main className="site-main mt-20" id="main" role="main">
          <section className="section-intro mt60" id="section-we-care">
            <div className="section-title">
              <h2>{heading1}</h2>
              <p>{heading1Description.heading1Description}</p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4 mb30">
                  <div className="col-img">
                    <Img alt="Healthy moms icon" fluid={col1Img.fluid} />
                  </div>
                  <h3>{col1Heading}</h3>
                  <p>{col1Desc.col1Desc}</p>
                </div>
                <div className="col-md-4 mb30">
                  <div className="col-img">
                    <Img alt="Healthy babies icon" fluid={col2Img.fluid} />
                  </div>
                  <h3>{col2Heading}</h3>
                  <p>{col2Desc.col2Desc}</p>
                </div>
                <div className="col-md-4 mb30">
                  <div className="col-img">
                    <Img alt="Hopeful hearts icon" fluid={col3Img.fluid} />
                  </div>
                  <h3>{col3Heading}</h3>
                  <p>{col3Desc.col3Desc}</p>
                </div>
              </div>
            </div>
          </section>

          <section id="section-we-help">
            <div className="section-title">
              <h2>{heading}</h2>
              <p>{statisticsDesc.description}</p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="embed-container">
                  <iframe
                    id="giving-tuesday-2024-video"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    data-src={bannerEmbeddedUrl}
                    title="Pearls of Value"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
      <section className="two-columns" id="section-sponsor-a-mom">
        <div className="container">
          <div className="two-columns-content row">
            <div className="col-md-6">
              <Img alt="Sponsor a mom" className="w-100" fluid={bigSectionImg.fluid} />
            </div>
            <div className="col-md-6">
              <h2>{bigSectionHeading}</h2>
              <p>{bigSectionDesc.bigSectionDesc}</p>
              <a className="btn-reg btn-purple p-3 mt-5" href={bigSectionButtonLink}>
                {bigSectionButtonText}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="two-columns testimonials" id="section-missionvision">
        <div className="container">
          <div className="two-columns-content row">
            <div className="col-md-5 col-sm-12">
              <a className="youtube-link" data-toggle="modal" href="#hometestiModal">
                <Img alt="testimonial" className="w-100" fluid={testimonialImg.fluid} />
              </a>
            </div>
            <div className="col-md-7 col-sm-12">
              <h4 className="testi-content">{testimonial.testimonial}</h4>
              <p className="translation purple">{testimonialTranslation.testimonialTranslation}</p>
              <p className="testi-author">
                {testimonialPerson} — <span className="purple">{testimonialPersonDesc}</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="hometestiModal">
        <div className="modal-dialog">
          <div className="modal-content transparent-modal">
            <div className="modal-header">
              <button aria-hidden="true" className="close" data-dismiss="modal" type="button">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-container">
                <iframe
                  allowFullScreen
                  data-src={testimonialVideoLink}
                  frameBorder="0"
                  title="testimonial"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-cta" id="section-help-a-mom">
        <div className="section-cta-inner">
          <div className="container">
            <div className="section-cta-content row">
              <div className="col-md-6">
                <h2>{sponsorSectionHeading}</h2>
                <p>{sponsorSectionDesc.sponsorSectionDesc}</p>
              </div>
              <div>
                <a className="btn-reg btn-purple float-right" href={sponsorSectionBtnLink}>
                  {sponsorSectionBtnText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-other-ways" id="section-other-ways">
        <div className="section-title">
          <h2>{sponsorshipHeading}</h2>
          <p>{sponsorshipDesc.description}</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 cards">
              <div className="sponsor-col-img">
                <Img alt="Sponsor a party icon" className="w-100" fluid={sponsorship1Img.fluid} />
              </div>
              <h3>{sponsorship1Heading}</h3>
              <p>{sponsorship1Desc.sponsorship1Desc}</p>
              <a className="learnmore-btn" href={sponsorship1Link}>
                {sponsorship1LinkText}
              </a>
            </div>
            <div className="col-md-4 cards">
              <div className="sponsor-col-img">
                <Img alt="Sponsor a mom icon" fluid={sponsorship2Img.fluid} className="w-100" />
              </div>
              <h3>{sponsorship2Heading}</h3>
              <p>{sponsorship2Desc.sponsorship2Desc}</p>
              <a className="learnmore-btn" href={sponsorship2Link}>
                {sponsorship2LinkText}
              </a>
            </div>
            <div className="col-md-4 cards">
              <div className="sponsor-col-img">
                <Img alt="Make donations icon" fluid={sponsorship3Img.fluid} className="w-100" />
              </div>
              <h3>{sponsorship3Heading}</h3>
              <p>{sponsorship3Desc.sponsorship3Desc}</p>
              <a className="learnmore-btn" href={sponsorship3Link}>
                {sponsorship3LinkText}
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const homePageQuery = graphql`
  query HomePage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            contentType
            date(formatString: "MMMM DD, YYYY")
            path
            hidden
          }
        }
      }
    }
    allContentfulPageHome {
      edges {
        node {
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          title
          heading1
          heading1Description {
            heading1Description
          }
          col1Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          col1Heading
          col1Desc {
            col1Desc
          }
          col2Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          col2Heading
          col2Desc {
            col2Desc
          }
          col3Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          col3Heading
          col3Desc {
            col3Desc
          }
          bigSectionImg {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          bigSectionHeading
          bigSectionDesc {
            bigSectionDesc
          }
          bigSectionButtonText
          bigSectionButtonLink
          testimonialImg {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          testimonialVideoLink
          testimonial {
            testimonial
          }
          testimonialTranslation {
            testimonialTranslation
          }
          testimonialPerson
          testimonialPersonDesc
          sponsorSectionHeading
          sponsorSectionDesc {
            sponsorSectionDesc
          }
          sponsorSectionBtnText
          sponsorSectionBtnLink
          mainSectionButtonText
          mainSectionButtonLink
          mainSectionButtonText2
          mainSectionButtonLink2
        }
      }
    }
    allContentfulGivingTuesday2020 {
      edges {
        node {
          bannerEmbeddedUrl
        }
      }
    }
    allContentfulSectionStatistics {
      edges {
        node {
          description {
            description
          }
          heading
        }
      }
    }
    allContentfulSectionOtherSponsorships {
      edges {
        node {
          heading
          description {
            description
          }
          sponsorship1Img {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship1Heading
          sponsorship1Desc {
            sponsorship1Desc
          }
          sponsorship1Link
          sponsorship1LinkText
          sponsorship2Img {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship2Heading
          sponsorship2Desc {
            sponsorship2Desc
          }
          sponsorship2Link
          sponsorship2LinkText
          sponsorship3Img {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship3Heading
          sponsorship3Desc {
            sponsorship3Desc
          }
          sponsorship3Link
          sponsorship3LinkText
        }
      }
    }
  }
`;